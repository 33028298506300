
.serviceContainer article{
    margin: 0% 2%;
}
.service{
    height: 260px;
    width: 260px;
    background: #FE9338;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 100px;
    position: relative;
    z-index: 20;
    margin: 0px 90px;
    margin-bottom: 100px;
    scale: 0;
}
.shadow{
    height: 260px;
    width: 260px;
    margin-left: 40px;
    margin-top: 30px;
    background: #4B73AF;
    position: absolute;
    z-index: 1;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.service span{
    height: 35px;
    width: 35px;
    margin-top: 10px;
}
.service span img{
    height: 100%;
    width: 100%;
}
.service h5{
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin: 1px 0px;
    width: 85%;
}
.service p{
    height: 35%;
    width: 85%;
    color: #fff;
    font-size: 13px;
}
.service h4{
    margin-top: 13px;
    color: #fff;
    margin-bottom: 15px;
}

@media screen and ( min-width : 2000px ) {
    .serviceContainer article{
        scale: 1.2;
    }
}
@media screen and ( min-width : 1500px ) {
    .serviceContainer article{
        margin: 0% 5%;
    }
}

@media screen and ( max-width : 350px ) {
    .service{
        margin: 0px;
        margin-bottom: 100px;
    }
    .shadow{
        height: 220px;
        width: 220px;
        margin-left: -26px;
        margin-top: 30px;
    }
    .header span{
        padding: 10px 40px;
        font-size: 1.8rem;
        font-weight: 900;
        background: #FE9338;
        color: #fff;
        left: 0;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
