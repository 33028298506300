#contactContainer{
    height: auto;
    position: relative;
    margin-bottom: 70px;
}
h3 {
    display: flex;
    justify-content: center;
    color: #4B73AF;
    font-weight: 100;
}

#formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
}

#formContainer form {
    height: auto;
    width: 700px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.inputWrapper {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

input:focus {
    outline-width: 0;
}

.lineInputBottom {
    height: 25px;
    border: 0px;
    border-bottom: 2px solid black;
    margin-top: 10px;
    padding-left: 5px;
    font-size: 0.93rem;
}

#question {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 20px 0px;
    font-weight: 100;
}

.inputWrapper span {
    color: #4B73AF;
}

#checkboxContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

}

.checkbox {
    width: auto;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
    color: #4B73AF;
}

#checkboxContainer label{
    cursor: pointer;
}
.line::placeholder {
    font-size: 0.93rem;
    opacity: 0.4;
}

.circle {
    color: #4B73AF;
    height: 25px;
    width: 20px;
    margin-right: 5px;
}

input[type=checkbox] {
    appearance: none;
    -webkit-appearance: none;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background: none;
    border: 1.5px solid #F48B29;
    margin: 5px;
    cursor: pointer;
}

input[type=checkbox]:checked{
    background: #f9ca9e;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#titleMessage {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 20px 0px;
    color: #4B73AF;
}

.text {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.bigLine {
    height: 25px;
    border: 0px;
    padding-left: 5px;
    border-bottom: 2px solid black;
}

#btnSend {
    width: 100%;
    color: #4B73AF;
    cursor: pointer;
}

#btnSend button {
    border: 0px;
    font-size: 1.3rem;
    width: 100%;
    height: 55px;
    color: white;
    background-color: #4B73AF;
    border-radius: 10px;
    cursor: pointer;
}

input::placeholder{
    opacity: 0.5;
    font-size: 0.9rem;
}

@media screen and (min-height: 800px) {
    #contactContainer{
        height: 87%;
    }
}

@media screen and (min-height: 1000px) {
    #contactContainer{
        height: 800px;
    }
}

@media screen and (min-width: 2000px) {
    #contactContainer{
        width: 2000px;
        margin: auto;
        position: relative;
    }
}

@media screen and ( max-width : 800px ) {
    #formContainer form {
        width: 80%;
    }
    .inputWrapper {
        width: 100%;
    }
    #checkboxContainer {
        flex-direction: column;
        justify-content: center;
    }
    .checkbox {
        width: 100%;        
        justify-content: center;
    }
    #formGroup {
        width: 100%;
        text-align: center;
    }
    #contactContainer {
        height: auto;
        padding: 50px 0px;
    }
}