.header{
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
}
.header span{
    padding: 10px 40px;
    font-size: 1.8rem;
    font-weight: 900;
    background: #FE9338;
    color: #fff;
    left: 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}