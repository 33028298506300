#aboutContainer{
    height: auto;
    width: 100%;
    position: relative;
}
#aboutContent{
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

@media screen and (min-width: 2000px) {
    #aboutContainer{
        width: 2000px;
        margin: auto;
        position: relative;
    }
}
@media screen and ( min-height: 1200px ) {

    #aboutContent {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }
}

@media screen and ( max-width: 1350px ) {
    #aboutContent{
        flex-wrap: wrap;
        padding-top: 40px;
        padding-bottom: 80px;
    }
}

@media screen and ( max-width: 550px ) {
    #aboutContainer{
        background-image: none;
    }
}
