@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

*{
    padding: 0;
    margin: 0;
    font-family: 'Inter', sans-serif;
}

#App {
  height: auto;
  width: 100vw;
  background: #fff;
  position: relative;
  padding-top: 10%;
}
html{
  scroll-padding-top: 150px;
}