#footerContainer  {
    height: 70px;
    width: 100%;
    background-color: #FF8A2A;
}

#footerContainer div {
    height: 100%;
    width: 80%;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

#footerContainer figure {
    display: flex;
    align-items: center;
}

#footerContainer figure span {
    margin-left: 10px;
}

#footerContainer img {
    height: 20px;
    width: auto;
}

@media screen and ( max-width : 800px ) {
    #footerContainer {
        height: auto;
    }
    #footerContainer div {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    #footerContainer div figure {
        margin: 9px;
    }
}

@media screen and ( max-width : 350px ) {
    #footerContainer figure span {
        font-size: 0.9rem;
    }
}