#homeContainer{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 30px;
}

#articleContent{
    height: auto;
    width: 900px;
    background: #fff;
    display: flex;
}

#articleContent::before{
    content: '';
    height: 30%;
    width: 6%;
    background: #FF8A2A;
    position: absolute;
    z-index: -1;
    margin-top: 200px;
    margin-left: -3%;
}

#articleContent article{
    height: auto;
    width: 50%;
    z-index: 20;
}

#articleContent article:nth-child(2){
    padding-left: 20px;
}

#articleContent article img{
    height: auto;
    width: 100%;
    z-index: 2;
}

#articleContent article h1{
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #948B8B;
}

#articleContent article h1 span{
    color: #FF8A2A;
}

#articleContent article p{
    width: 90%;
    font-size: 1.1rem;
    color: #948B8B;
}

#location {
    height: auto;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 0px;
    margin-left: 30%;
    margin-bottom: 30px;
    z-index: 20;
}

#location h4{
    align-self: flex-start;
    font-size: 2rem;
    color: #FF8A2A;
    margin: 47px 0px;
}

#location span{
    text-align: right;
    padding: 10px 30px;
    font-size: 1.5rem;
    background: #FF8A2A;
    color: #fff;
    align-self: flex-end;
}

#location::before{
    position: absolute;
    content: '';
    height: 50px;
    width: 220px;
    background: #4B73AF;
    z-index: -1;
    bottom: 0;
    margin-bottom: -20px;
    margin-right: 30px;
    align-self: flex-end;
}

@media screen and (min-width: 2000px) {
    #articleContent article{
        height: 100%;
    }
    #articleContent{
        height: 40%;
        width: 60%;
    }
    .header nav ul li{
        font-size: 1.5rem;
        margin: 0% 10%;
    }
    #logo{
        scale: 1.3;
    }
    #articleContent article h1{
        margin: 20px 0px;
        font-size: 2.3rem;
    }
    #articleContent article p{
        width: 90%;
        font-size: 1.8rem;
    }
}
@media screen and (max-width: 1250px) {
    .header nav{
        display: none;
    }
    #burgeur{
        display: block;
    }
    #logo div span{
        font-size: 1rem;
        margin: 10px 10px;
    }
    #logo img{
        scale: 1;
    }
    #burgerContent{
        display: flex;
    }
}
@media screen and (max-width: 900px) {
    #articleContent{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 60px;
    }
    #articleContent{
        width: 100%;
    }
    #articleContent article {
        margin-top: 30px;
        height: auto;
        width: 60%;
    }
    #homeContainer{
        height: auto;
    }
}
@media screen and (max-width: 650px) {
    #logo div{
        display: none;
    }
    #logo{
        width: 100px;
    }
    #logo img{
        scale: 1.1;
    }
    #location{
        margin-left: 0%;
        width: 100%;
    }
    #location h4{
        font-size: 1.5rem;
        align-self: initial;
    }
    #location span{
        font-size: 1.5rem;
        align-self: initial;
    }
    #location::before{
        height: 40px;
        width: 200px;
        margin-bottom: -10px;
        margin-right: 170px;
    }
}
@media screen and (max-width: 500px) {
    #articleContent article {
        margin-top: 30px;
        height: auto;
        width: 80%;
    }
    #location::before{
        height: 40px;
        width: 200px;
        margin-bottom: -10px;
        margin-right: 10%;
    }
}