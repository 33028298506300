#servicePage{
    height: auto;
    width: 100%;
}
svg{
    fill:#ffffff
}
.serviceContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
@media screen and (max-width: 500px) {
    .serviceContainer {
        overflow-x: hidden;
    }
}
@media screen and (min-width: 2000px) {
    #servicePage{
        width: 2000px;
        margin: auto;
        position: relative;
    }
}