#mapContainer{
    height: 100%;
}

.map { 
    display: "flex";
    flex-direction: "column";
    align-items: "center";
    margin: 10% auto;
    width: 50%;
    border: 3px solid #F48B29;
}

@media screen and (max-width: 1250px) {
    .map { 
        width: 70%;
    }
}

@media screen and (max-width: 500px) {
    .map { 
        width: 70%;
    }
}