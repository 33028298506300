#burgerContent {
    display: none;
    height: 40px;
    width: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
}

#burgerContent img {
    height: 30%;
    width: 100%;
}

.navbar {
    height: 120px;
    width: 100%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;

    z-index: 200;
}

#logo {
    height: 150px;
    width: 320px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#logo div {
    height: 100px;
    width: 200px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
}

#logo div span {
    font-size: 1rem;
    margin: 15px 10px;
    border-left: 2px solid #ff8a2a;
    padding-left: 5px;
    color: #ff8a2a;
}

#logo img {
    height: 100%;
    flex: 1;
    scale: 1;
    cursor: pointer;
}

.navbar nav {
    height: 100%;
    width: 600px;
}

.navbar nav ul {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.navbar nav ul li {
    padding: 7px 30px;
    background: #ff8a2a;
    text-decoration: none;
    list-style: none;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
    cursor: pointer;
}

.navbar nav ul li:hover {
    transition: 0.6s;
    background: #4b73af;
}

#responsive_panel {
    position: fixed;
    z-index: 100;
    display: none;
    height: 100vh;
    width: 100vw;
    background: #fff;
    transform: translateX(-1000px);
}

#responsive_panel nav {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#responsive_panel nav ul {
    overflow: hidden;
}

#responsive_panel nav li {
    list-style: none;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin: 50% 0%;
    font-weight: 600;
    color: #ff8a2a;
    transform: translateX(-500px);
}

#responsive_panel nav li img {
    height: 20px;
    width: auto;
}

.span_responsive {
    text-align: center;
}

@media screen and (min-width: 2000px) {
    .navbar nav ul li {
        font-size: 1.5rem;
        margin: 0% 10%;
    }
    #logo {
        scale: 1.3;
    }
}
@media screen and (max-width: 1250px) {
    .navbar nav {
        display: none;
    }
    #burgeur {
        display: block;
    }
    #logo div span {
        font-size: 1rem;
        margin: 10px 10px;
    }
    #logo img {
        scale: 1;
    }
    #burgerContent {
        display: flex;
    }
}
@media screen and (max-width: 650px) {
    #logo div {
        display: none;
    }
    #logo {
        height: 100%;
        width: 100px;
    }
    #logo img {
        scale: 1.1;
    }
}
