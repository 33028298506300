#aboutContent article:nth-child(2){
    margin-bottom: 300px;
}
#aboutContent article{
    height: 350px;
    width: 400px;
    position: relative;
    z-index: 2;
    margin: 0px 5%;
    margin-bottom: 100px;
}
.about{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    z-index: 2;
    scale: 0.9;
}
.about figure{
    height: 200px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.about figure img{
    height: 100%;
    width: 100%;
}
.about div{
    height: 120px;
    width: 100%;
    background: #FF8A2A;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
#aboutContent article::before{
    content: '';
    height: 120px;
    width: 100%;
    position: absolute;
    background: #4B73AF;
    z-index: -1;
    margin-right: 60px;
    margin-top: 260px;
}
.about div p{
    width: 85%;
    color: #fff;
    font-weight: 100;
    font-size: 1rem;
}

@media screen and ( max-width: 1500px ) {
    .about div{
        height: 120px;
        width: 120%;
    }
    #aboutContent article::before{
        height: 120px;
        width: 120%;
    }
}

@media screen and ( max-width: 1350px ) {
    #aboutContent article:nth-child(2){
        margin-bottom: 0px;
    }
    .about div{
        height: 120px;
        width: 100%;
    }
    #aboutContent article::before{
        height: 120px;
        width: 100%;
    }
    #aboutContent article{
        margin: 50px 90px;
        margin-bottom: 0px;
    }
}

@media screen and ( max-width: 550px ) {
    .about figure{
        height: 120px;
        width: 150px;
    }
    .about div{
        height: 100px;
        width: 100%;
        margin-bottom: 100px;
    }
    #aboutContent article::before{
        height: 80px;
        width: 100%;
        margin-top: 200px;
    }
    .about div p{
        font-size: 0.8rem;
    }
    #aboutContent article {
        margin: 43px 50px;
    }
}

@media screen and ( max-width: 400px ) {
    #aboutContent article {
        margin: 13px 30px;
    }
    #aboutContent article::before{
        margin-right: 100px;
        width: 100%;
    }
    .about div p{
        width: 90%;
    }
}

@media screen and ( max-width: 310px ) {
    .about div p{
        font-size: 0.75rem;
    }
    .about figure{
        height: 100px;
        width: 130px;
    }
    #aboutContent article {
        margin: 1px 30px;
    }
    .about div{
        margin-bottom: 120px;
        width: 110%;
    }
    #aboutContent article::before{
        margin-top: 180px;
    }
}